import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLead(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('lead', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getLeadById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`lead/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveLead`, leadData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listSection(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('listSection', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('listStatus', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveStatus(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveStatus`, leadData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    removeStatus(ctx, leadActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeStatus`, leadActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('listContacts')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    listClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getClientAll',{params:queryParams})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    saveLeadProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveLeadProject', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    saveSection(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSection`, leadData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveLeadComment(ctx, leadCommentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveLeadComment`, leadCommentData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    editComment(ctx, leadCommentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`editComment`, leadCommentData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listLeadComments(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`listLeadComments/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listLeadActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`listLeadActivity/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveLeadActivity(ctx, leadActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveLeadActivity`, leadActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateActivityMessage(ctx, leadActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateActivityMessage`, leadActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    cancelLeadMeeting(ctx, leadActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`cancelLeadMeeting`, leadActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    removeSection(ctx, leadActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeSection`, leadActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSection(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSection`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSectionName(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSectionName`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateStatus`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateStatusName(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateStatusName`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`lead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateLeadIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateLeadIsCompleted`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    interactionLog(ctx, interactionLog) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`interactionLog`, interactionLog)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateAssignLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`assignUpdate/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('leadUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadLead', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchLeads(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('leadsList', { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateOrderNumbers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateOrderNumbers', { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/apps/todo/tasks', { task: taskData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/apps/todo/tasks/${task.id}`, { task })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/apps/todo/tasks/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
